import axios from 'axios'

export default {
  namespaced: true,
  state: {
    records: [],
  },
  getters: {
    allRecords: state => {
      return state.records
    },
    oneRecordById: state => id => {
      return state.records.find(record => record._id === id)
    },
    allRecordsDates: state => state.records.map(record => record.date),
  },
  actions: {
    async getAllRecords({ commit, rootGetters, dispatch }) {
      const res = await axios({
        method: 'get',
        url: 'api/records'
      })
      commit(
        'SET_RECORDS',
        res.data.map(record => ({
          ...record,
          createdAt: new Date(record.createdAt).toISOString(),
          date: new Date(record.date).toISOString().substr(0, 10),
          convent: rootGetters['data/oneConventById'](record.convent),
        }))
      )
      dispatch('docs/setLoading', false, { root: true })
    },
    async addNewRecord({ commit, dispatch }, record) {
      record.createdAt = Date.now()
      const res = await axios({
        method: 'post',
        url: 'api/records/',
        data: record
      })
      dispatch('records/getAllRecords', null, { root: true })
    },
    async updateOneRecord({ commit, dispatch }, newRecord) {
      newRecord.updatedAt = Date.now()
      const res = axios({
        mehtod: 'put',
        url: `api/records/${newRecord._id}`,
        data: newRecord
      }) // res for messageing
      dispatch('records/getAllRecords', null, { root: true })
    },
    async deleteRecord({ commit }, id) {
      await axios({
        method: 'delete',
        url: `api/records/${id}`
      })
      commit('DELETE_RECORD', id)
    },
    emptyStore({ commit }) {
      commit('EMPTY_STORE')
    },
  },
  mutations: {
    SET_RECORDS: (state, records) => (state.records = records),
    DELETE_RECORD: (state, id) => (state.records = state.records.filter(rec => rec._id !== id)),
    EMPTY_STORE: state => { state.records = [] },
  },
}
