<template>
  <v-row class="ma-2">
    <v-card class="ma-3 pa-2" max-width="800" shaped raised>
      <v-list-item three-line>
        <v-list-item-content>
          <div class="headline text-uppercase mb-4">Abiks sisestajale</div>
          <div>
            Märksõna saab lisada <strong>'Alt+A'</strong> klahvikombinatsiooniga. <br/>
            Kommentaari saab salvestada <strong>'Enter'</strong> klahviga.
          <hr/>
            <br/><u>Võimalusel täita kõik tekstiväljad.</u>
            <br/><strong>Protokolli numbriks</strong> võib selle puudumisel sisestada 0.
            <br/><strong>Kuupäev</strong> tuleb vajadusel sisestada hinnanguliselt ning seda kommenteerida vastavalt.
          <hr/>
            <br/>Sissejuhatus, päevakord, sisutekst ja lisamärkused toetavad Markdown formaati.
            <br/>Täpsemalt https://miaolz123.github.io/vue-markdown/
          <hr/>
            <br/>Kroonikakirjete eesmärk on lühikirjeldustega anda edasi korp! Rotalia
            ajaloos toimunud sündmused. Allikatele viitamine aitab vajadusel hiljem kattuvaid kuupäevi täpsustada.
          <hr/>
            <br/>Probleemide ja ideede korral pöördu vil! Karl Jaago poole <a href="mailto:ka.jaago@gmail.com?Subject=Protokollide sisestamine">ka.jaago@gmail.com</a>
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-card>
    <v-card class="ma-3" max-width="600" min-width="150" shaped raised>
      <v-list-item three-line>
        <v-list-item-content>
          <div class="overline mb-4">Protokollid</div>
          <v-list-item-title :class="[!$vuetify.breakpoint.xsOnly ? 'headline' : 'subtitle', 'mb-1']">Protokolle kokku: {{ allDocs.length }}</v-list-item-title>
          <v-list-item-subtitle>Viimati sisestatud protokollid:</v-list-item-subtitle>
          <ul>
            <li v-for="(entry,index) in lastEntries" :key="index">
              {{ entry.book.nimi + " (" + dateToLocaleEST(entry.date, 1) + ") &rarr; " + (entry.updatedAt != null ? dateToLocaleEST(entry.updatedAt,2) : dateToLocaleEST(entry.createdAt,2) ) }}
            </li>
          </ul>
          <hr><br/>
          <v-list-item-subtitle>Sina oled sisestanud: {{ getUserEntries(user).length }}</v-list-item-subtitle>
          <ul>
            <li v-for="(entry,index) in userLastEntries" :key="index">
              {{ entry.book.nimi + " (" + dateToLocaleEST(entry.date, 1) + ") &rarr; " + (entry.updatedAt != null ? dateToLocaleEST(entry.updatedAt,2) : dateToLocaleEST(entry.createdAt,2) ) }}
            </li>
          </ul>
        </v-list-item-content>
      </v-list-item>
      <v-card-actions>
        <v-row class="justify-center pa-3">
          <v-btn text :to="{ name: 'collection' }">Lugema<v-icon class="ma-2">mdi-book-open-variant</v-icon></v-btn>
          <v-btn text :to="{ name: 'editor', params: { id: 'new' } }">Sisestama<v-icon class="ma-2">mdi-book-plus</v-icon></v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-card class="ma-3" max-width="600" min-width="150" shaped raised>
      <v-list-item three-line>
        <v-list-item-content>
          <div class="overline mb-4">Kroonika</div>
          <v-list-item-title :class="[!$vuetify.breakpoint.xsOnly ? 'headline' : 'subtitle', 'mb-1']">Kroonikakirjeid: {{ allRecords.length }} </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-card-actions>
        <v-row class="justify-center pa-3">
          <v-btn text :to="{ name: 'records' }">Vaatama<v-icon class="ma-2">mdi-plus-box-multiple</v-icon></v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-row>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'Dashboard',
  data() {
    return {
      date: ''
    }
  },
  computed: {
    ...mapGetters({
      user: 'users/getUser',
      // allBooks: 'data/allBooks',
      // allKeywords: 'data/allKeywords',
      allDocs: 'docs/allDocs',
      allDocsDates: 'docs/allDocsDates',
      allRecords: 'records/allRecords',
      allRecordsDates: 'records/allRecordsDates',
      getUserEntries: 'docs/getUserEntries'
    }),
    userLastEntries() {
      return this.getUserEntries(this.user)
        .sort( (a, b) => new Date(b.updatedAt != null ? b.updatedAt : b.createdAt) - new Date(a.updatedAt != null ? a.updatedAt : a.createdAt) )
        .slice( 0, 5 )
    },
    lastEntries() {
      return this.allDocs
        .sort( (a, b) => new Date(b.updatedAt != null ? b.updatedAt : b.createdAt) - new Date(a.updatedAt != null ? a.updatedAt : a.createdAt) )
        .slice( 0, 5 )
    }
  },
  methods: {
    dateToLocaleEST(dateString, type) {
      switch(type) {
        case 1:
          return new Date(dateString).toLocaleDateString('ET-ee', { month: 'long', day: 'numeric', year: 'numeric' })
        case 2:
          return new Date(dateString).toLocaleDateString('ET-ee', { weekday: 'long', month: 'long', day: 'numeric', })
      }
    },
  }
}
</script>
